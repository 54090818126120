import * as Sentry from "@sentry/browser";
import { get, omitBy } from "lodash";
import { warningToast } from "toast-service!sofe";
import {
  getAssociatedService,
  getSquad,
  getVersioningInformation,
  getFeatureToggleInfo,
} from "./error.helpers.js";

function getWhiteListUrls() {
  return [
    "canopy.ninja",
    "canopy.dev",
    "canopytax.com",
    "localhost",
    "ielocal",
    // white label domains
    "clientportal.ninja",
    "clientportal.com",
  ];
}

let appIsUnloading = false;

export function beforeSend(event, hint) {
  if (appIsUnloading) {
    return null; // Skip errors that occur while the app is unloading (or reloading)
  }

  // The following is added because of an issue with 3rd party scripts throwing
  // empty strings on the login page for windows 10 chrome users which sentry
  // is unable to handle. https://github.com/getsentry/sentry-javascript/issues/3440
  if (
    event !== undefined &&
    event.exception !== undefined &&
    event.exception.values !== undefined &&
    event.exception.values.length > 0
  ) {
    var e = event.exception.values[0];
    if (
      e.type === "UnhandledRejection" &&
      e.value.includes("Non-Error promise rejection captured with value:")
    ) {
      return null;
    }
  }

  try {
    const { exception } = event;
    const frames = get(exception, "values[0].stacktrace.frames");

    const errorUrl = frames ? frames[frames.length - 1].filename : "";
    let error = hint.originalException;
    if (typeof error === "string") error = new Error(error);

    if (
      errorUrl &&
      !getWhiteListUrls().find((matcher) => errorUrl.includes(matcher))
    )
      return null; // the error occurred from an unknown domain

    event = addErrorData(event, error, window.loggedInUser);
  } catch (resolutionError) {
    console.error("Error resolving error: ", resolutionError);
  }

  return Promise.resolve(event);
}

function addErrorData(event, error, loggedInUser = {}) {
  const manifest = omitBy(window.sofe_manifest || {}, (val, key) => {
    return key.includes("/!sofe") || !val.endsWith(".js");
  });
  if (error && error.showToast) {
    const toastBreadcrumb = {
      category: "console",
      level: "error",
      message: `WARNING-TOAST: ${error.toastMessage}`,
      timestamp: Date.now() / 1000,
    };

    event = {
      ...event,
      breadcrumbs: [...(event.breadcrumbs || []), toastBreadcrumb],
    };
  }

  if (error) {
    const service = error.service
      ? error.service
      : getAssociatedService(event.exception, manifest);
    const versions = getVersioningInformation(manifest, service);
    const toggles = getFeatureToggleInfo();
    const ft_crm =
      window.tenant?.crm_status === "crm_hierarchy_complete" && toggles?.ft_crm;

    event = {
      ...event,
      tags: {
        ...(event.tags || {}),
        ...(error.tags || {}),
        ft_crm: ft_crm,
        toastDisplayed: error.showToast,
        toastMessage: error.toastMessage,
        service: service,
        squad: getSquad(service),
        userName: loggedInUser.name,
        userEmail: loggedInUser.email,
        userRole: loggedInUser.role,
      },
      extra: {
        ...(event.extra || {}),
        ...(error.extra || {}),
        toastMessage: error.toastMessage,
        toggles: JSON.stringify(toggles),
        versions: JSON.stringify(versions),
      },
    };
  }

  return event;
}

export function handleToaster(error) {
  if (error && error.showToast !== false) {
    error.showToast = true;
    error.toastMessage =
      error.toastMessage ||
      get(error, "data.errors.userMessage") ||
      `Something went wrong... We're working fast to fix it!`;
    warningToast(error.toastMessage);
  }
  return error;
}

function updateOnlineStatus() {
  Sentry.addBreadcrumb({
    category: "connection",
    message:
      "Online status changed: " + (navigator.onLine ? "online" : "offline"),
    level: "info",
  });
}

window.addEventListener("online", updateOnlineStatus);
window.addEventListener("offline", updateOnlineStatus);

window.addEventListener("beforeunload", function () {
  appIsUnloading = true;
});
